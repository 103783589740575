import styled from 'styled-components';
import { tablet } from 'utils/media';
import theme from 'utils/theme';

interface SectionProps {
  bg?: 'light' | 'dark' | 'normal' | 'irisPurple' | null;
  padding?: 'none' | 'small' | 'medium';
}

const bgColors = {
  irisPurple: theme.colors.irisPurple,
  light: theme.colors.white,
  normal: theme.colors.offWhite,
  dark: theme.colors.darkBg,
};

const paddingSizes = {
  none: {
    mobile: '0',
    tablet: '0',
  },
  small: {
    mobile: '10px 0',
    tablet: '30px 0',
  },
  medium: {
    mobile: '40px 0',
    tablet: '60px 0',
  },
};

const Section = styled.section<SectionProps>(({ bg, padding = 'medium' }) => ({
  backgroundColor: bg ? bgColors[bg] : undefined,
  padding: paddingSizes[padding].mobile,

  [tablet]: {
    padding: paddingSizes[padding].tablet,
  },
}));

export default Section;
